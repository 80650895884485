<template>
  <div>
    <a-spin :spinning="spining">
      <a-space direction="vertical" class="content-container">
        <a-form-model
          class=""
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-space direction="vertical" style="width: 100%">
            <p>
              <b>
                Выполните поиск по существующим дилерам, если дилер не найден,
                то нажмите кнопку "Новый дилер" для создания нового дилера.
              </b>
            </p>
            <a-form-model-item label="Код" prop="code">
              <a-input v-model="form.code" v-mask="'RSXXX'" />
            </a-form-model-item>
            <a-form-model-item label="ИНН" prop="inn">
              <a-input v-model="form.inn" />
            </a-form-model-item>
            <a-form-model-item label="Название организации" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="submitForm('ruleForm')">
                Поиск
              </a-button>
              <a-button
                style="margin-left: 10px"
                @click="resetForm('ruleForm')"
              >
                Очистить форму
              </a-button>
            </a-form-model-item>
          </a-space>
        </a-form-model>
        <a-table
          :locale="{
            emptyText: 'Организаций не найдено',
          }"
          bordered
          :columns="[
            {
              title: 'Код',
              dataIndex: 'code',
              key: 'code',
              width: 100,
            },
            {
              title: 'ИНН',
              dataIndex: 'legalInfo.inn',
              key: 'inn',
            },
            {
              title: 'Название организации',
              dataIndex: 'legalInfo.name',
              key: 'name',
            },
            {
              title: 'Действия',
              dataIndex: 'operation',
              scopedSlots: { customRender: 'operation' },
              className: 'column-action',
              width: 110,
            },
          ]"
          :data-source="clients"
          :rowKey="row => row.id"
          class="ordertable"
          :pagination="true"
        >
          <template slot="operation" slot-scope="text, record">
            <a-tooltip title="В архив">
              <a-button
                shape="circle"
                icon="plus"
                size="small"
                @click="onAdd(record.id)"
                tooltip="В архив"
              />
            </a-tooltip>
          </template>
        </a-table>
        <a-button type="primary" @click="gotonew()">Новый дилер</a-button>
      </a-space>
    </a-spin>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  data() {
    return {
      spining: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        code: "",
        inn: "",
        name: "",
      },
      rules: {
        code: [
          {
            validator: (rule, value, callback) => {
              if (!value && !this.form.inn && !this.form.name) {
                callback(
                  new Error(
                    "Код, ИНН или Название организации должно быть заполнено"
                  )
                )
              } else callback()
            },
          },
        ],
        inn: [
          {
            validator: (rule, value, callback) => {
              let e = /^(\d{10}|\d{12})$/
              if (value && !e.test(value)) {
                callback(new Error("Введите верный ИНН"))
              } else if (!value && !this.form.name && !this.form.code) {
                callback(
                  new Error(
                    "Код, ИНН или Название организации должно быть заполнено"
                  )
                )
              } else callback()
            },
          },
        ],
        name: [
          {
            validator: (rule, value, callback) => {
              if (!value && !this.form.inn && !this.form.code) {
                callback(
                  new Error(
                    "Код, ИНН или Название организации должно быть заполнено"
                  )
                )
              } else callback()
            },
          },
        ],
      },
    }
  },
  name: "CheckClientExist",
  computed: {
    ...mapGetters({
      clients: "clients/clients",
    }),
  },
  mounted() {
    this.setClients([])
  },
  components: {},
  methods: {
    async onAdd(id) {
      try {
        this.spining = true
        await this.addClient(id)
        this.spining = false
        this.clearFilters()
        this.$router.push({ name: "Clients" })
        this.$message.success("Клиент добавлен")
      } catch (error) {
        this.$message.error(error.message)
        this.spining = false
      }
    },
    gotonew() {
      this.$router.push({
        name: "CreateNewClient",
        query: { name: this.form.name, inn: this.form.inn },
      })
    },
    async search() {
      this.spining = true
      this.setFilterName(this.form.name)
      this.setFilterInn(this.form.inn)
      this.setFilterCode(this.form.code)
      await this.fetchClients()
      this.spining = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.search()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.setClients([])
    },
    ...mapMutations({
      setFilterName: "clients/SET_FILTER_NAME",
      setFilterInn: "clients/SET_FILTER_INN",
      setFilterCode: "clients/SET_FILTER_CODE",
      setClients: "clients/SET_CLIENTS",
      clearFilters: "clients/CLEAR_FILTERS",
    }),
    ...mapActions({
      fetchClients: "clients/searchClients",
      addClient: "clients/bindExistingClientToDealer",
    }),
  },
}
</script>

<style scoped></style>
<style lang="scss" scoped>
.divider {
  margin-bottom: -30px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
</style>
