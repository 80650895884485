var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.spining}},[_c('a-space',{staticClass:"content-container",attrs:{"direction":"vertical"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-space',{staticStyle:{"width":"100%"},attrs:{"direction":"vertical"}},[_c('p',[_c('b',[_vm._v(" Выполните поиск по существующим дилерам, если дилер не найден, то нажмите кнопку \"Новый дилер\" для создания нового дилера. ")])]),_c('a-form-model-item',{attrs:{"label":"Код","prop":"code"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('RSXXX'),expression:"'RSXXX'"}],model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',{attrs:{"label":"ИНН","prop":"inn"}},[_c('a-input',{model:{value:(_vm.form.inn),callback:function ($$v) {_vm.$set(_vm.form, "inn", $$v)},expression:"form.inn"}})],1),_c('a-form-model-item',{attrs:{"label":"Название организации","prop":"name"}},[_c('a-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(" Поиск ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v(" Очистить форму ")])],1)],1)],1),_c('a-table',{staticClass:"ordertable",attrs:{"locale":{
          emptyText: 'Организаций не найдено',
        },"bordered":"","columns":[
          {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            width: 100,
          },
          {
            title: 'ИНН',
            dataIndex: 'legalInfo.inn',
            key: 'inn',
          },
          {
            title: 'Название организации',
            dataIndex: 'legalInfo.name',
            key: 'name',
          },
          {
            title: 'Действия',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
            className: 'column-action',
            width: 110,
          } ],"data-source":_vm.clients,"rowKey":function (row) { return row.id; },"pagination":true},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('a-tooltip',{attrs:{"title":"В архив"}},[_c('a-button',{attrs:{"shape":"circle","icon":"plus","size":"small","tooltip":"В архив"},on:{"click":function($event){return _vm.onAdd(record.id)}}})],1)]}}])}),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.gotonew()}}},[_vm._v("Новый дилер")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }